<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" width="1050">
            <v-card class="px-4">
                <v-card-title class="mb-4">
                    <span class="text-h4 font-weight-bold">选择课程</span>
                </v-card-title>
                <!-- <v-row justify="center"> -->
                <v-row>
                    <v-col cols="12" md="10" offset="1">
                        <v-data-table
                            v-model="selected"
                            :headers="headers"
                            :items="datas"
                            item-key="course_id"
                            :items-per-page="10"
                            :items-per-page-options="[5, 10, 15, -1]"
                            class="elevation-2 row-pointer"
                            show-select
                            :single-select="singleSelect"
                            :hide-default-footer="datas.length <= 10"
                            :footer-props="{
                                showFirstLastPage: true,
                                itemsPerPageText: '每页行数',
                                pageText: '',
                                'items-per-page-all-text': '全部',
                            }"
                        >
                            <template v-slot:item.name="{ item }">
                                <div
                                    class="font-weight-medium"
                                >
                                    {{ item.name }}
                                </div>
                            </template>
                            <template v-slot:item.course_type="{ item }">
                                <v-chip :color="getTypeColor(item.course_type)" dark >
                                    {{ item.course_type }}
                                </v-chip>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="grey"
                        text
                        @click="cancel()"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="green darken-1"
                        text
                        @click="submit"
                    >
                        确认
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { getAdminCourseList } from "@/api/admin";
import { getTypeColor } from '@/utils/common';

export default {
    name: "SelectCourseDlg",

    data: function () {
        return {
            dialog: false,

            headers: [
                { text: "课程名称", value: "name", width: 200, align: "start" },
                // { text: "知识点", value: "points", sortable: false },
                { text: "类型", value: "course_type", width: 120, align: "center" },
                { text: "内容介绍", value: "desc", sortable: false },
                { text: "难度", value: "level", width: 80 },
            ],
            datas: [],

            singleSelect: false,
            
            courseType: '',
            groupId: '',
            selected: [],
        };
    },
    computed: {},
    created() {},
    activated() {
        // this.loadData();
    },
    mounted() {
        // this.loadData();
    },
    watch: {},
    methods: {
        getTypeColor,
        loadData() {
            getAdminCourseList({scope: "PRIVATE", courseType: this.courseType}).then((response) => {
                // console.log(response.data);
                this.datas = response.data;
            })
            .catch(function (err) {
                console.log(err);
            });
        },
        openDlg(groupId = '', selected = []) {
            // console.log(groupId);
            this.groupId = groupId
            this.selected = selected
           
            this.dialog = true

            this.loadData();
        },
        cancel() {
            this.dialog = false
        },
        submit() {
            // console.log(this.selected)

            this.$emit("submit", {
                groupId: this.groupId,
                selected: this.selected
            })
            this.dialog = false
        },
    },
    components: {},
};
</script>

<template>
    <v-container fluid class="px-8 mb-8">
        <!-- 课程包基本内容和状态 -->
        <v-card class="course-info mb-12" elevation="2">
            <v-img
                :src="getImgUrl(topic.cover, defaultImg4Topic)"
                height="125"
                width="200"
                max-width="200"
                class="grey darken-4 me-5"
            ></v-img>

            <div class="course-desc">
                <div class="desc-col">
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium">
                            课程包名称：
                        </div>
                        <div class="text">{{ topic.name }}</div>
                    </div>
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium">
                            课程包说明：
                        </div>
                        <div class="text">{{ getText(topic.desc, "暂无说明") }}</div>
                    </div>
                </div>
            </div>
            <div class="operate">
                <!-- <v-btn
                    tile
                    dark
                    color="orange"
                    class="font-weight-bold text-body-1"
                    @click.stop="openConfirmDlg(topic.topic_id)"
                >
                    <v-icon left> mdi-shopping-outline </v-icon>
                    立即购买
                </v-btn> -->
                <v-btn
                    tile
                    dark
                    color="orange"
                    :disabled="topic.is_my_topic"
                    class="font-weight-bold text-body-1"
                    @click.stop="openConfirmDlg(topic.topic_id)"
                >
                    <v-icon left> mdi-download </v-icon>
                    <!-- {{ topic.is_my_topic ? "已下载" : "立即下载" }} -->
                    {{ topic.is_my_topic ? "已购买" : "立即购买" }}
                </v-btn>
            </div>
        </v-card>

        <!-- 分组列表 -->
        <draggable
            :disabled="disabledGroupList"
            v-model="groups"
            @change="groupListChange"
            v-if="groups.length > 0"
        >
        <v-card
            class="course-group-wrapper mb-5"
            elevation="2"
            v-for="group in groups"
            :key="group.group_id"
        >
            <!-- 课程分组信息：Cover、标题、说明 -->
            <div class="group-info-wrapper">
                <div class="group-img">
                    <v-img
                        :src="getImgUrl(group.cover, defaultImg4Group)"
                        height="120"
                        width="120"
                        max-width="120"
                        class=""
                        contain
                    ></v-img>
                </div>

                <div class="group-info">
                    <div class="group-desc" @click.stop="toggleCourseList(group)">
                        <div class="mb-4 desc-row">
                            <div class="desc-row-name">标题：</div>
                            <div class="text-h5 font-weight-light">
                                {{ group.name }}
                            </div>
                        </div>
                        <div class="desc-row">
                            <div class="desc-row-name">说明：</div>
                            <div class="text-h5 font-weight-light">
                                {{ group.desc }}
                            </div>
                        </div>
                    </div>
                    <div class="btn-group">
                        <v-btn
                            class="ml-4"
                            fab
                            outlined
                            x-small
                            color="grey lighten-1"
                            @click.stop="toggleCourseList(group)"
                        >
                            <v-icon dark size="24" color="grey lighten-1" v-if="group.isShow"> mdi-chevron-up </v-icon>
                            <v-icon dark size="24" color="grey lighten-1" v-else> mdi-chevron-down </v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>

            <!-- 分组课程列表 -->
            <div class="group-course-list" v-if="group.isShow">
                <div class="course-list">
                    <div class="list-group-headers">
                        <div class="index">序号</div>
                        <div class="type">类型</div>
                        <div class="name">课程名称</div>
                        <div class="desc">课程说明</div>
                        <div class="op">预览</div>
                        <!-- <div class="op">操作</div> -->
                    </div>
                    <draggable
                        :list="group.courses"
                        :group="{ name: 'group', pull: 'clone', put: true }"
                        :disabled="disabledCourseList"
                        class="list-group"
                        ghost-class="ghost"
                        @change="courseListChange(group.group_id, $event)"
                    >
                        <div
                            class="list-group-item"
                            v-for="(item, index) in group.courses"
                            :key="index"
                        >
                            <div class="group-item">
                                <div class="index">{{ index + 1 }}</div>
                                <div class="type">
                                    <v-chip :color="getTypeColor(item.course_type)" small dark >
                                        {{ getTypeFirstLetter(item.course_type) }}
                                    </v-chip>
                                </div>
                                <div class="name">
                                    <div class="active-name"
                                        @click.stop="goto(item.cid)"
                                    >
                                        {{ item.name }}
                                    </div>
                                </div>
                                <div class="desc">
                                    {{ getText(item.desc, "暂无说明") }}
                                </div>
                                <div class="op">
                                    <v-btn
                                        tile
                                        dark
                                        color="orange"
                                        class="font-weight-bold text-body-1"
                                        style="margin: 0;"
                                        max-height="36"
                                        @click.stop="goto(item.cid)"
                                        :disabled="!item.can_preview"
                                    >
                                        <v-icon left> mdi-eye </v-icon>
                                        预览
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </draggable>
                </div>
            </div>
        </v-card>
        </draggable>

        <EditTopicDlg ref="editTopicDlg" @submit="submitTopic" />
        <EditTopicGroupDlg ref="editTopicGroupDlg" @submit="submitGroup" />

        <SelectCourseDlg ref="selectCourseDlg" @submit="submitSelectCourse" />

        <ConfirmDlg
            ref="buyTopicConfirmDlg"
            title="购买课程包"
            text="确认 购买 当前课程包么？"
            keyword=" 购买 "
            @confirm="buyTopicConfirm"
        />

    </v-container>
</template>

<script>
import draggable from "vuedraggable";
import EditTopicDlg from "@/views/components/dialog/EditTopicDlg";
import EditTopicGroupDlg from "@/views/components/dialog/EditTopicGroupDlg";
import SelectCourseDlg from "@/views/components/dialog/SelectCourseDlg";
import ConfirmDlg from "@/views/components/dialog/ConfirmDlg";
import { getText, getImgUrl, getTypeFirstLetter } from "@/utils/util";
import { getTypeColor } from '@/utils/common';
import {
    getAdminTopicStoreInfo,
    getAdminTopicStoreGroupList,
    updateAdminGroupListIndex,
    getAdminTopicStoreGroupCourseRelations,
    updataAdminGroupCourseRelations,
    editAdminTopic,
    editAdminGroup,
    buyAdminTopic,
    onlineTopic,
} from "@/api/admin";

export default {
    name: "TopicGroup4Preview",

    data() {
        return {
            defaultImg4Topic: require("@/assets/images/admin/default-cover.jpeg"),
            defaultImg4Group: require("@/assets/images/admin/default-group.png"),

            topicId: "",
            topic: {},
            groups: [
                // 结构说明：
                // {
                //     id: '',
                //     group_id: '',
                //     topic_id: '',
                //     name: '',
                //     desc: '',
                //     cover: '',
                //     index: 0,
                //     courses: [], // 每个分组中的课程列表
                // }
            ],
            disabledGroupList: true,
            disabledCourseList: true,
            dragging: false,
        };
    },
    mounted() {
    },
    activated() {
        if (this.$route.query.id) {
            this.topicId = this.$route.query.id;
        }
        this.loadData();
    },
    methods: {
        getText,
        getImgUrl,
        getTypeColor,
        getTypeFirstLetter,
        goto(id) {
            if (id != '') {
                this.$router.push({
                    path: "/course-mgr/topic-list/topic-preview/course-preview",
                    query: { id: id },
                });
            }
        },
        async loadData() {
            // 获取主题信息
            let res = await getAdminTopicStoreInfo(this.topicId)
            this.topic = res.data;

            // 获取主题分组列表
            res = await getAdminTopicStoreGroupList(this.topicId)
            if (res && res.data) {
                this.groups = res.data
            
                for (let i = 0; i < this.groups.length; i++) {
                    // console.log(this.groups[i])
                    let group = this.groups[i]
                    // console.log(group)
                    let res = await getAdminTopicStoreGroupCourseRelations(group.group_id)
                    if (res.data) {
                        // 暂时前端控制只能预览前3个课程
                        // TODO: 改成后端控制
                        for (let j = 0; j < res.data.length; j++) {
                            if (j < 3) {
                                res.data[j]['can_preview'] = true
                            } else {
                                res.data[j]['can_preview'] = false
                                res.data[j]['cid'] = ''
                            }
                        }
                        this.groups[i].courses = res.data
                        this.groups[i].isShow = false
                    }
                }
                // 默认将一个分组展开
                this.groups[0].isShow = true
            }

            // console.log(this.groups)
            this.$forceUpdate()
        },

        // 对话框
        openEditTopicDlg(topic = null) {
            this.$refs.editTopicDlg.openDlg(topic);
        },
        openEditTopicGroupDlg(group = null) {
            this.$refs.editTopicGroupDlg.openDlg(group);
        },

        // 选择课程对话框，咱们先不用，后面再添加按钮
        openSelectCourseDlg(group) {
            let type = group.type
            let groupId = group.group_id
            let selected = []
            for (let i in this.groups) {
                if (this.groups[i].group_id == groupId) {
                    selected = this.groups[i].courses
                }
            }
            this.$refs.selectCourseDlg.openDlg(type, groupId, selected);
        },
        openConfirmDlg(id) {
            this.$refs.buyTopicConfirmDlg.openDlg(id);
        },

        // 打开创建课程对话框
        openEditCourseDlg(course = null, groupId = "",) {
            this.$refs.editCourseDlg.openDlg(course, groupId);
        },
        // 表单对话框确认callback
        submitTopic(newTopic) {
            editAdminTopic({
                topicId: this.topicId,
                name: newTopic.name,
                desc: newTopic.desc,
                cover: newTopic.cover,
                index: newTopic.index,
            }).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    this.$store.dispatch("web/SetAlert", {
                        type: "success",
                        msg: "创建分组成功",
                    });

                    // 将主题更新到当前页面中
                    let result = response.data;
                    this.topic.name = result.name;
                    this.topic.desc = result.desc;
                    this.topic.cover = result.cover;
                } else {
                    this.$store.dispatch("web/SetAlert", {
                        type: "error",
                        msg: "创建分组失败",
                    });
                }
            })
            .catch(function (err) {
                console.log(err);
            });
        },

        // 添加分组
        submitGroup(newGroup) {
            editAdminGroup({
                topicId: this.topicId,
                id: newGroup.group_id,
                name: newGroup.name,
                desc: newGroup.desc,
                cover: newGroup.cover,
                index: newGroup.index,
            }).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    this.$store.dispatch("web/SetAlert", {
                        type: "success",
                        msg: "创建分组成功",
                    });

                    // 将新增分组添加到当前页面中
                    let result = response.data;
                    if (newGroup.group_id == "") {
                        // 创建
                        this.groups.push(result);
                        // 新建后更新分组List的Index
                        this.updateAdminGroupListIndex()

                    } else {
                        // 修改
                        for (let i in this.groups) {
                            if (this.groups[i].group_id == newGroup.group_id) {
                                this.groups[i].name = result.name;
                                this.groups[i].desc = result.desc;
                                this.groups[i].cover = result.cover;
                            }
                        }
                    }
                } else {
                    this.$store.dispatch("web/SetAlert", {
                        type: "error",
                        msg: "创建分组失败",
                    });
                }
            })
            .catch(function (err) {
                console.log(err);
            });
        },
        submitSelectCourse(result) {
            // console.log(result.selected)
            for (let i in this.groups) {
                if (this.groups[i].group_id == result.groupId) {
                    // 合并选项
                    // this.groups[i].courses 是当前分组中的课程列表
                    // result.selected 是所有选中的课程列表
                    let newList = this.getNewCourseList(this.groups[i].courses, result.selected)
                    this.groups[i].courses = newList

                    this.$forceUpdate()
                    break
                }
            }
            this.updataAdminGroupCourseRelations(result.groupId)
        },
        // 选择课程后，更新当前分组课程列表
        getNewCourseList(curList, selected) {
            let newList = []

            // 更新selected标记，全部为新增
            for (let i = 0; i < selected.length; i++) {
                selected[i].isNew = true
            }

            // 先遍历当前课程列表
            // 如果课程在select中，push；更新selected标记
            // 如果课程不在select中，pass；
            for (let i = 0; i < curList.length; i++) {
                let pos = selected.findIndex(item => {
                    return item.cid == curList[i].cid
                })
                if (pos >= 0) {
                    newList.push(curList[i])
                    selected[pos].isNew = false
                }
            }

            // 将selected标记为isNew（新增）的，push到newList末尾
            for (let i = 0; i < selected.length; i++) {
                if (selected[i].isNew) {
                    // console.log(selected[i])
                    newList.push(selected[i])
                }
            }
            return newList
        },

        // 确认对话框confirm callback
        onlineConfirm() {
            // console.log("We got Online~");
            // console.log(this.topic.topic_id);

            onlineTopic(this.topic.topic_id).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    this.$store.dispatch("web/SetAlert", {
                        type: "success",
                        msg: "主题上线成功",
                    })

                    // 更新页面数据
                    this.topic.is_online = 1

                } else {
                    this.$store.dispatch("web/SetAlert", {
                        type: "error",
                        msg: "主题上线失败",
                    })
                }
            }).catch(function (err) {
                console.log(err)
            })
        },
        offlineConfirm() {
            // console.log("We got Offline~");
            console.log(this.topic.topic_id);
        },
        buyTopicConfirm(id) {
            buyAdminTopic(id).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    this.topic.is_my_topic = true

                    this.$store.dispatch("web/SetAlert", {
                        type: "success",
                        msg: "购买成功",
                    });
                }
            })
            .catch(function (err) {
                console.log(err);
            });
        },

        // draggable列表事件
        groupListChange(e) {
            // console.log(e)
            this.updateAdminGroupListIndex()
        },
        courseListChange(groupId, e) {
            if (e.added) {
                // console.log('added')
                // console.log(e.added)

                // 说明：
                // 因为支持从其他分组列表中拖拽clone，
                // 所以添加时可能会有重复的课程，需要删掉

                let cid = e.added.element.cid
                let findCount = 0
                let findIndex = -1

                for (let i = 0; i < this.groups.length; i++) {
                    //找到对应分组
                    if (this.groups[i].group_id == groupId) {

                        // 找到分组中重复的课程cid，并删除
                        for (let j = 0; j < this.groups[i].courses.length; j++) {
                            if (this.groups[i].courses[j].cid == cid) {
                                findIndex = j
                                findCount++
                            }
                        }
                        if (findCount >= 2 && findIndex >= 0) {
                            this.groups[i].courses.splice(findIndex, 1);
                        }

                        break
                    }
                }
            }
            // if (e.removed) {
            //     console.log('removed')
            // }
            // if (e.moved) {
            //     console.log('moved')
            // }

            // 更新courses列表中各个元素的index
            // 看起来不需要了，直接保存数据库的时候更新就行
            // this.updateCourseListIndex(groupId)

            // 目前这个地方简化处理，就是直接把对应的分组删除，然后创建新的项
            this.updataAdminGroupCourseRelations(groupId)

            this.$forceUpdate()
        },
        clone(e) {
            // console.log(e)
        },
        async updateAdminGroupListIndex() {
            let groupIds = []
            for (let i = 0; i < this.groups.length; i++) {
                groupIds.push(this.groups[i].group_id)
            }
            
            await updateAdminGroupListIndex({
                id: this.topicId,
                groupIds: groupIds
            });
        },
        async updataAdminGroupCourseRelations(groupId) {
            for (let i = 0; i < this.groups.length; i++) {
                //找到对应分组
                if (this.groups[i].group_id == groupId) {
                    let courseIds = []

                    let courses = this.groups[i].courses
                    for (let j = 0; j < courses.length; j++) {
                        courseIds.push(courses[j].cid)
                    }

                    await updataAdminGroupCourseRelations({
                        id: groupId,
                        courseIds: courseIds
                    })
                    break
                }
            }
        },
        // 更新courses列表中各个元素的index
        updateCourseListIndex(groupId) {
            for (let i = 0; i < this.groups.length; i++) {
                //找到对于分组
                if (this.groups[i].group_id == groupId) {
                    for (let j = 0; j < this.groups[i].courses.length; j++) {
                        this.groups[i].courses[j].index = j
                    }
                    break
                }
            }
        },

        toggleCourseList(group) {
            // 如果当前列表已经展开，直接关闭
            // 如果当前列表关闭状态，先将其他分组列表关闭，再将其打开
            if (group.isShow) {
                group.isShow = false

            } else {
                for (let i = 0; i < this.groups.length; i++) {
                    this.groups[i].isShow = false
                }
                group.isShow = true
            }

            this.$forceUpdate()
        }
    },

    components: {
        draggable,
        EditTopicDlg,
        EditTopicGroupDlg,
        SelectCourseDlg,
        ConfirmDlg,
    },
};
</script>

<style lang="scss" scoped>
.alert-area {
    position: absolute;
    width: 100%;
    z-index: 10;
    top: 0;
}
// .move-pointer {
//     cursor: move;
// }
.view-pointer {
    cursor: pointer;
}
// 去掉v-card默认的上下的margin
.v-card {
    margin: 0;
}

.add-btn-row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.ghost {
    opacity: 0.5;
    background: #dddedf;
}

.course-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .course-desc {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .desc-col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0 20px;
        }
        .desc-row {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 35px;
            .desc-name {
                width: 70px;
                min-width: 70px;
            }
        }
        .text {
            text-align: left;
            font-weight: 300;
            font-size: 14px;
        }
    }
}

.operate {
    // height: 125px;
    height: 100%;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.course-group-wrapper {
    display: flex;
    flex-direction: column;

    .group-info-wrapper {
        // flex: 4;
        display: flex;
        justify-content: space-between;
        // align-items: flex-start;
        align-items: center;
        position: relative;
        background-color: rgb(247, 247, 247);

        .group-img {
            // margin-right: 20px;
            padding: 20px;
            // background-color: #fff;
            // border-bottom: 1px solid #efefef;
            // &:hover {
            //     cursor: move;
            // }
        }

        .group-info {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .group-desc {
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin-left: 15px;
                min-height: 120px;
                &:hover {
                    // cursor: move;
                    cursor: pointer;
                }
                .desc-row {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    height: 35px;
                    text-align: left;

                    .desc-row-name {
                        height: 28px;
                        width: 50px;
                        font-size: 16px;
                        font-weight: bold;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                    }
                }
            }

            .btn-group {
                // position: absolute;
                display: flex;
                align-items: center;
                // right: 12px;
                // bottom: 15px;
                height: 120px;
                margin-left: 8px;
                margin-right: 30px;
            }
        }
    }
    .group-course-list {
        flex: 6;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        // background-color: #f9f8f2;

        .course-list {
            flex: 1;
            // height: 125px;

            .list-group-headers {
                height: 48px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #eee;
                font-size: 14px;
                font-weight: bold;
                color: #555;
                
                .index {
                    width: 60px;
                }
                .name {
                    flex: 1;
                    text-align: center;
                }
                .desc {
                    flex: 1;
                    text-align: left;
                }
                .status {
                    width: 100px;
                }
                .type {
                    width: 60px;
                }
                .op {
                    width: 150px;
                }
            }

            .list-group {
                height: 100%;
                align-items: flex-start;

                .list-group-item {
                    text-align: left;
                    height: 55px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid #eee;
                    .group-item {
                        display: flex;
                        align-items: center;
                        flex: 1;
                        // padding-left: 20px;
                        .handle {
                            width: 60px;
                            cursor: move;
                        }
                        .index {
                            // margin-right: 8px;
                            // color: #fff;
                            // font-weight: bold;
                            // background-color: #333;
                            width: 60px;
                            height: 22px;
                            border-radius: 25px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .name {
                            flex: 1;
                            font-size: 14px;
                            font-weight: bold;
                            text-align: center;
                            display: flex;
                            justify-content: center;
                            .active-name {
                                padding: 8px 25px;
                                border-radius: 50px;
                                // background-color: #efefef;
                                background-color: #fff;
                                &:hover {
                                    cursor: pointer;
                                    background-color: #fff;
                                    border-radius: 50px;
                                }
                            }
                        }
                        .desc {
                            flex: 1;
                            font-size: 14px;
                        }
                        .status {
                            width: 100px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .type {
                            width: 60px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .op {
                            width: 150px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                    .item-op {
                        width: 60px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    
                    &:hover {
                        // background-color: #ebebeb;
                        background-color: #F5F5F5;
                    }
                }
            }
        }
    }
}
</style>
